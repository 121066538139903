<template>
	<div class="c-app flex-row align-items-center authen-page">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol md="8">
					<CCardGroup>
						<!-- <CCard
							color="primary"
							text-color="white"
							class="text-center py-5 col-4 d-md-down-none"
							body-wrapper
						>
							<CCardBody class="h-100">
								<img v-lazy="logo" alt class="logo" />
							</CCardBody>
						</CCard> -->
						<CCard class="p-4">
							<CCardBody>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(loginOTP)">
										<h1 class="pb-4">
											{{ $t("OTP.Title") }}
										</h1>
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_otp"
												:rules="`requiredChar`"
											>
												<div :class="classes">
													<input
														v-model.trim="otp"
														type="text"
														class="form-control"
														:placeholder="$t('OTP.Field')"
														autocomplete="OTP"
														:maxlength="maxLength"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</ValidationProvider>
										</div>

										<CRow>
											<CCol col="6" class="text-left">
												<button class="px-4 btn btn-outline-dark" type="button" @click="resendOTP">
													{{ $t("OTP.Resend") }}
												</button>
											</CCol>
											<CCol col="6" class="text-right">
												<button class="px-4 btn btn-outline-custom" type="submit">
													{{ $t("OTP.Submit") }}
												</button>
											</CCol>
										</CRow>
									</form>
								</ValidationObserver>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
import logo from "@/assets/img/logo_small.jpg"
import { GET_OTP_AGAIN, SEND_OTP_FORGOT } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
import CustomToastr from "@/shared/plugins/toastr"
const { mapActions, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "SendOTP",

	data() {
		return {
			logo,
			otp: null,
			maxLength: process.env.VUE_APP_OTP_LENGTH,
		}
	},

	computed: {
		...mapState(["loginForm", "OTPErr", "emailForgot"]),
	},

	watch: {
		OTPErr(val) {
			if (val)
				// this.$refs.form.setErrors(val)
				CustomToastr.error(val.message)
		},
	},

	methods: {
		...mapActions({ SEND_OTP_FORGOT, GET_OTP_AGAIN }),

		loginOTP: async function () {
			let params = { ...this.emailForgot, otp: this.otp }
			await this.SEND_OTP_FORGOT(params)
		},

		resendOTP: async function () {
			await this.GET_OTP_AGAIN()
		},
	},
}
</script>
